import { Injectable } from '@angular/core';
import { ProductionPeriodDetailData, WasteAssignmentInTime, WastePerReason, WorkCenterTask } from 'chronos-core-client';
import { TelemetryResultDto } from 'chronos-panda-client';
import moment from 'moment-mini';
import { ChartType } from '../models/chart-type.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SignalType } from './machine-data';
import { FormatQuantityPipe } from '../pipes/format-quantity/format-quantity.pipe';
@Injectable({
  providedIn: 'root'
})
export class MachineChartConfigurationService {
  public runModeText: string;
  public targetSpeedText: string;
  public setupModeText: string;
  public downModeText: string;
  public downtimeCodeText: string;
  public downtimeCommentText: string;
  public noCommentText: string;
  public startText: string;
  public restText: string;
  public shtCounterText: string;
  public pcsCounterText: string;
  public shiftText: string;
  public externalShiftTypeText: string;
  public dateText: string;
  public productionOrderNumberText: string;
  public finishGoodArticleNumberText: string;
  public finishGoodArticleNameText: string;
  public configNameNumberText: string;
  public goodQuantityUnitText: string;
  public wasteQuantMalQuntText: string;
  public dateFormatText: string;
  public speed2UnitText: string;
  public productionChangeSeries: string;
  public speedSeriesText: string;
  public speed2SeriesText: string;
  public washSeriesText: string;
  public downtimeTaskText: string;
  public newSeriesName: string;
  public fullViewSeriesData: any[] = [];
  public availableSignalSeries: any[] = [];
  public wasteQuantityText: string;
  public maculatureQuantityText: string;
  public wasteReasonText: string;
  public setupKindText: string;

  constructor(private translate: TranslateService, private formatQuantityPipe: FormatQuantityPipe) {
    this.setChartTranslations();
  }

  public setChartTranslations() {
    this.translate
      .stream([
        'MACHINE_CHART.RUN_MODE_TEXT',
        'MACHINE_CHART.TARGET_SPEED_TEXT',
        'MACHINE_CHART.SETUP_MODE_TEXT',
        'MACHINE_CHART.DOWN_MODE_TEXT',
        'MACHINE_CHART.DOWNTIME_CODE_TEXT',
        'MACHINE_CHART.DOWNTIME_COMMENT_TEXT',
        'MACHINE_CHART.NO_COMMENT',
        'MACHINE_CHART.START',
        'MACHINE_CHART.REST',
        'MACHINE_CHART.SHT_UNIT',
        'MACHINE_CHART.PCS_UNIT',
        'MACHINE_CHART.SHIFT',
        'MACHINE_CHART.EXTERNAL_SHIFT_TYPE',
        'MACHINE_CHART.DATE',
        'MACHINE_CHART.PRODUCTION_ORDER_NUMBER',
        'MACHINE_CHART.FINISH_GOOD_ARTICLE_NUMBER',
        'MACHINE_CHART.FINISH_GOOD_ARTICLE_NAME',
        'MACHINE_CHART.CONFIGURATION_NUMBER_AND_NAME',
        'MACHINE_CHART.GOOD_QUANITY_UNIT',
        'MACHINE_CHART.WASTE_QUANTITY_MACULATURE_QUANTITY',
        'MACHINE_CHART.DATE_FORMAT',
        'MACHINE_CHART.SPEED_SERIES',
        'MACHINE_CHART.SPEED2_UNIT',
        'MACHINE_CHART.SPEED2_SERIES',
        'MACHINE_CHART.WASH_SERIES',
        'MACHINE_CHART.DOWNTIME_TASK',
        'MACHINE_CHART.WASTE_QUANTITY',
        'MACHINE_CHART.MACULATURE_QUANTITY',
        'MACHINE_CHART.WASTE_REASON',
        'MACHINE_CHART.SETUP_KIND'
      ])
      .subscribe((values) => {
        Object.keys(values).map((key) => {
          switch (key) {
            case 'MACHINE_CHART.SPEED_SERIES':
              this.speedSeriesText = values[key];
              break;
            case 'MACHINE_CHART.SPEED2_SERIES':
              this.speed2SeriesText = values[key];
              break;
            case 'MACHINE_CHART.WASH_SERIES':
              this.washSeriesText = values[key];
              break;
            case 'MACHINE_CHART.RUN_MODE_TEXT':
              this.runModeText = values[key];
              break;
            case 'MACHINE_CHART.TARGET_SPEED_TEXT':
              this.targetSpeedText = values[key];
              break;
            case 'MACHINE_CHART.SETUP_MODE_TEXT':
              this.setupModeText = values[key];
              break;
            case 'MACHINE_CHART.DOWN_MODE_TEXT':
              this.downModeText = values[key];
              break;
            case 'MACHINE_CHART.DOWNTIME_CODE_TEXT':
              this.downtimeCodeText = values[key];
              break;
            case 'MACHINE_CHART.DOWNTIME_COMMENT_TEXT':
              this.downtimeCommentText = values[key];
              break;
            case 'MACHINE_CHART.NO_COMMENT':
              this.noCommentText = values[key];
              break;
            case 'MACHINE_CHART.START':
              this.startText = values[key];
              break;
            case 'MACHINE_CHART.REST':
              this.restText = values[key];
              break;
            case 'MACHINE_CHART.SHT_UNIT':
              this.shtCounterText = values[key];
              break;
            case 'MACHINE_CHART.PCS_UNIT':
              this.pcsCounterText = values[key];
              break;
            case 'MACHINE_CHART.SHIFT':
              this.shiftText = values[key];
              break;
            case 'MACHINE_CHART.EXTERNAL_SHIFT_TYPE':
              this.externalShiftTypeText = values[key];
              break;
            case 'MACHINE_CHART.DATE':
              this.dateText = values[key];
              break;
            case 'MACHINE_CHART.PRODUCTION_ORDER_NUMBER':
              this.productionOrderNumberText = values[key];
              break;
            case 'MACHINE_CHART.FINISH_GOOD_ARTICLE_NUMBER':
              this.finishGoodArticleNumberText = values[key];
              break;
            case 'MACHINE_CHART.FINISH_GOOD_ARTICLE_NAME':
              this.finishGoodArticleNameText = values[key];
              break;
            case 'MACHINE_CHART.CONFIGURATION_NUMBER_AND_NAME':
              this.configNameNumberText = values[key];
              break;
            case 'MACHINE_CHART.GOOD_QUANITY_UNIT':
              this.goodQuantityUnitText = values[key];
              break;
            case 'MACHINE_CHART.WASTE_QUANTITY_MACULATURE_QUANTITY':
              this.wasteQuantMalQuntText = values[key];
              break;
            case 'MACHINE_CHART.DATE_FORMAT':
              this.dateFormatText = values[key];
              break;
            case 'MACHINE_CHART.SPEED2_UNIT':
              this.speed2UnitText = values[key];
              break;
            case 'MACHINE_CHART.DOWNTIME_TASK':
              this.downtimeTaskText = values[key];
              break;
            case 'MACHINE_CHART.WASTE_QUANTITY':
              this.wasteQuantityText = values[key];
              break;
            case 'MACHINE_CHART.MACULATURE_QUANTITY':
              this.maculatureQuantityText = values[key];
              break;
            case 'MACHINE_CHART.WASTE_REASON':
              this.wasteReasonText = values[key];
              break;
            case 'MACHINE_CHART.SETUP_KIND':
              this.setupKindText = values[key];
              break;
          }
        });
      });
  }

  public getSplitOrEditDowntimeConfiguration(
    viewAreaEndDate: Date,
    maxSpeedAxis1: number,
    counterUnitId: string,
    isSplitDowntime: boolean
  ): any {
    return {
      chart: {
        height: 400,
        marginTop: 20,
        animation: false,
        zooming: {
          type: 'x',
          mouseWheel: true
        }
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: ''
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          day: ' %k:%M <br> %e %b '
        },
        max: isSplitDowntime === true ? null : viewAreaEndDate.valueOf()
      },
      yAxis: {
        title: {
          text: this.getSeriesTitleText(counterUnitId)
        },
        max: maxSpeedAxis1
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          dragDrop: {
            draggableX: true,
            groupBy: 'x'
          }
        },
        line: {
          cursor: 'pointer'
        }
      },
      tooltip: {
        formatter() {
          if (!this.point.noTooltip) {
            return this.point.tooltipValue;
          }
          return false;
        }
      },
      time: {
        useUTC: false
      },
      series: [],
      exporting: {
        enabled: false
      }
    };
  }

  public getEditSetupConfigurationWithoutScroll(viewAreaEndDate: Date, maxSpeedAxis1: number, counterUnitId: string): any {
    return {
      chart: {
        height: 400,
        marginTop: 20,
        animation: false,
        zooming: {
          type: 'x',
          mouseWheel: true
        }
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: ''
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          day: ' %k:%M <br> %e %b '
        },
        max: viewAreaEndDate.valueOf()
      },
      yAxis: {
        title: {
          text: this.getSeriesTitleText(counterUnitId)
        },
        max: maxSpeedAxis1
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          dragDrop: {
            draggableX: true,
            groupBy: 'x'
          }
        },
        line: {
          cursor: 'pointer'
        }
      },
      tooltip: {
        formatter() {
          if (!this.point.noTooltip) {
            return this.point.tooltipValue;
          }
          return false;
        }
      },
      time: {
        useUTC: false
      },
      series: [],
      exporting: {
        enabled: false
      }
    };
  }

  public getEditSetupConfiguration(viewAreaEndDate: Date, maxSpeedAxis1: number, counterUnitId: string): any {
    return {
      chart: {
        height: 400,
        marginTop: 20,
        animation: false,
        zooming: {
          type: 'x',
          mouseWheel: true
        }
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: ''
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          day: ' %k:%M <br> %e %b '
        },
        max: viewAreaEndDate.valueOf(),
        scrollbar: {
          enabled: true,
          barBackgroundColor: 'gray',
          liveRedraw: false,
          barBorderRadius: 5,
          buttonBorderRadius: 5,
          barBorderWidth: 0,
          rifleColor: 'gray',
          trackBackgroundColor: 'white',
          trackBorderWidth: 1,
          trackBorderColor: 'gray',
          trackBorderRadius: 5
        }
      },
      yAxis: {
        title: {
          text: this.getSeriesTitleText(counterUnitId)
        },
        max: maxSpeedAxis1
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          dragDrop: {
            draggableX: true,
            groupBy: 'x'
          }
        },
        line: {
          cursor: 'pointer'
        }
      },
      tooltip: {
        formatter() {
          if (!this.point.noTooltip) {
            return this.point.tooltipValue;
          }
          return false;
        }
      },
      time: {
        useUTC: false
      },
      series: [],
      exporting: {
        enabled: false
      }
    };
  }

  public getFullViewConfiguration(
    workCenterName: string,
    externalWorkCenterID: string,
    maxSpeedAxis1: number,
    maxSpeedAxis2: number,
    counterUnitId: string,
    chartHeight: number
  ): any {
    return {
      chart: {
        type: 'area',
        animation: false,
        borderColor: '#E6E6E6',
        borderWidth: 1,
        height: chartHeight,
        style: {
          fontFamily: 'Roboto,sans-serif;'
        },
        zooming: {
          type: 'x',
          mouseWheel: true
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: `${externalWorkCenterID}-${workCenterName}`
      },
      time: {
        useUTC: false
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          day: ' %k:%M <br> %e %b '
        },
        labels: {
          style: {
            font: '14px "Roboto", sans-serif;'
          }
        }
      },
      tooltip: {
        valueDecimals: 0,
        useHTML: true,
        formatter() {
          if (this.point.noTooltip) {
            return this.point.tooltipValue;
          }

          if (this.series.name === 'Waste') {
            return `<div class="wastetooltip"> ${this.point.tooltipValue} </div>`;
          } else {
            return this.point.tooltipValue;
          }
        }
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false
          }
        }
      },
      yAxis: [
        {
          title: {
            text: this.getSeriesTitleText(counterUnitId)
          },
          max: maxSpeedAxis1,
          labels: {
            style: {
              font: '14px "Roboto", sans-serif;'
            }
          }
        },
        {
          title: {
            text: this.speed2UnitText
          },
          opposite: true,
          max: maxSpeedAxis2,
          min: 0,
          showEmpty: false,
          labels: {
            style: {
              font: '14px "Roboto", sans-serif;'
            }
          }
        }
      ],
      series: [],
      exporting: {
        enabled: true
      }
    };
  }

  public getFinishPhaseConfiguration(
    orderScheduleId: string,
    startTime: string,
    timeRemaining: string,
    counterUnitId: string,
    chartType: string
  ): any {
    return {
      chart: {
        type: 'area',
        animation: false,
        borderColor: '#E6E6E6',
        borderWidth: 1,
        height: 300,
        style: {
          fontFamily: '"Roboto", sans-serif;'
        },
        zooming: {
          type: 'x',
          mouseWheel: true
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      title: {
        text:
          chartType === ChartType.downtimeTask
            ? this.downtimeTaskText
            : `<b>${orderScheduleId}</b> <span style=\\"font-size:15px\\">
              (${this.startText}:${startTime} | ${this.restText}:${timeRemaining} min)  </span>`,
        align: 'left',
        margin: 20
      },
      time: {
        useUTC: false
      },
      xAxis: [
        {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%H:%M',
            day: ' %k:%M <br> %e %b '
          }
        }
      ],
      yAxis: {
        title: {
          text: this.getSeriesTitleText(counterUnitId)
        }
      },
      tooltip: {
        valueDecimals: 0,
        formatter() {
          if (!this.point.noTooltip) {
            return this.point.tooltipValue;
          }
          return false;
        }
      },
      series: [],
      exporting: {
        enabled: false
      }
    };
  }

  public getSeriesConfiguration(
    result: ProductionPeriodDetailData[],
    allDataSignal: TelemetryResultDto[],
    wasteReasons: WasteAssignmentInTime[],
    maxSpeedAxis1: number,
    setupStartTime: Date,
    setupEndTime: Date,
    chartMode: string,
    plotBandHeight: number,
    isMarkerEnabled: boolean,
    downtimeResult: WorkCenterTask
  ): any[] {
    this.availableSignalSeries = [];
    const setupDownLimit = (15 / 100) * maxSpeedAxis1;
    const runLimit = (5 / 100) * maxSpeedAxis1;
    const setupSeries = {
      name: this.setupModeText,
      data: [],
      step: 'left',
      type: 'area',
      color: '#FAE3B3',
      inside: true,
      states: {
        inactive: {
          opacity: 1
        }
      },
      marker: {
        enabled: isMarkerEnabled
      },
      fillColor: '#FAE3B3',
      showInLegend: false
    };

    const runSeries = {
      name: this.runModeText,
      data: [],
      step: 'left',
      type: 'area',
      color: '#00B7B9',
      inside: true,
      fillColor: '#00B7B9',
      lineColor: '#00A3A5',
      turboThreshold: 10000,
      states: {
        inactive: {
          opacity: 1
        }
      },
      marker: {
        enabled: isMarkerEnabled
      },
      showInLegend: true,
      yAxis: 0
    };

    const downSeries = {
      name: this.downModeText,
      cursor: 'pointer',
      data: [],
      type: 'area',
      color: '#83D6F4',
      inside: true,
      step: 'left',
      states: {
        inactive: {
          opacity: 1
        }
      },
      showInLegend: false,
      allowPointSelect: true,
      marker: {
        enabled: isMarkerEnabled,
        states: {
          select: {
            fillColor: '#83D6F4'
          }
        }
      },
      dataLabels: [
        {
          enabled: true,
          align: 'center',
          style: {
            textShadow: 'none',
            fontSize: '13px'
          },
          x: 14,
          y: 20,
          useHTML: true,
          formatter() {
            return ` <div>${this.point.externalDowntimeLocalCode ?? '!!! No downtime code found !!!'}</div>`;
          }
        }
      ]
    };

    const speedSeries = {
      name: this.speedSeriesText,
      data: [],
      type: 'area',
      step: 'left',
      color: '#689576',
      inside: true,
      fillColor: '#689576',
      lineColor: '#006E45',
      turboThreshold: 10000,
      states: {
        inactive: {
          opacity: 1
        }
      },
      yAxis: 0,
      marker: {
        enabled: isMarkerEnabled
      }
    };

    const targetSpeedSeries = {
      name: this.targetSpeedText,
      data: [],
      type: 'line',
      dashStyle: 'Dash',
      color: '#393d37',
      inside: true,
      step: 'left',
      showInLegend: true,
      marker: {
        enabled: isMarkerEnabled
      },
      states: {
        inactive: {
          opacity: 1
        }
      }
    };

    const plotBandSeries = {
      name: 'plotBandSeries',
      data: [],
      type: 'area',
      color: '#C8D4DF',
      inside: true,

      states: {
        inactive: {
          opacity: 1
        }
      },
      showInLegend: false,
      tooltip: {
        enabled: false
      },
      marker: {
        enabled: false
      },
      lineColor: '#C8D4DF',
      enableMouseTracking: false
    };

    const setupStartSeries = {
      name: 'setupStartSeries',
      type: 'line',
      stickyTracking: false,
      dragDrop: {
        draggableX: chartMode === ChartType.editDowntime ? false : true,
        groupBy: 'groupId',
        dragMaxX: moment(setupEndTime).add(1, 'hours').toDate().valueOf()
      },
      marker: {
        enabled: isMarkerEnabled
      },
      cursor: chartMode === ChartType.editDowntime ? 'none' : 'pointer',
      data: [
        {
          x: moment.utc(setupStartTime).valueOf(),
          y: maxSpeedAxis1,
          groupId: 'a',
          marker: {
            enabled: chartMode === ChartType.editDowntime ? false : true,
            fillColor: '#00325C',
            useHTML: true,
            cursor: 'Pointer',
            symbol: 'url(/assets/images/phase-setup_arrow_left.png)',
            className: 'plotline-left',
            radius: 10,
            align: 'left'
          },
          noTooltip: true,
          dataLabels: {
            align: 'left',
            enabled: true,
            useHTML: true,
            formatter() {
              return `<div class="plotline-left-label">${moment(this.x).format('HH:mm')}</div>`;
            }
          }
        },
        {
          x: moment.utc(setupStartTime).valueOf(),
          y: 0,
          noTooltip: true,
          groupId: 'a',
          marker: {
            enabled: true,
            radius: 0
          }
        }
      ],
      lineWidth: 3,
      lineColor: '#00325C',
      showInLegend: false,
      color: '#00325C',
      states: {
        inactive: {
          opacity: 1
        }
      }
    };

    const setupEndSeries = {
      name: 'setupEndSeries',
      type: 'line',
      stickyTracking: false,
      dragDrop: {
        draggableX: true,
        groupBy: 'groupId',
        dragMaxX: moment(setupEndTime).add(1, 'hours').toDate().valueOf()
      },
      marker: {
        enabled: isMarkerEnabled
      },
      cursor: 'Pointer',
      className: 'display-label',
      data: [
        {
          noTooltip: true,
          x: moment.utc(setupEndTime).valueOf(),
          y: maxSpeedAxis1,
          groupId: 'a',
          marker: {
            enabled: true,
            fillColor: '#00325C',
            useHTML: true,
            cursor: 'Pointer',
            symbol: 'url(/assets/images/phase-setup_arrow_right.png)',
            className: 'plotline-left',
            radius: 10,
            align: 'right'
          },
          dataLabels: {
            align: 'right',
            enabled: true,
            useHTML: true,
            formatter() {
              return `<div class="plotline-right-label">${moment(this.x).format('HH:mm')}</div>`;
            }
          }
        },
        {
          noTooltip: true,
          x: moment.utc(setupEndTime).valueOf(),
          y: 0,
          groupId: 'a',
          marker: {
            enabled: false,
            radius: 0
          }
        }
      ],
      lineWidth: 3,
      lineColor: '#00325C',
      showInLegend: false,
      color: '#00325C',
      states: {
        inactive: {
          opacity: 1
        }
      }
    };

    const editDowntimeEndSeries = {
      name: 'enditDowntimeEndSeries',
      type: 'line',
      stickyTracking: false,
      dragDrop: {
        draggableX: true,
        groupBy: 'groupId',
        dragMaxX: moment(setupEndTime).add(3, 'hours').toDate().valueOf(),
        dragMinX: moment(setupEndTime).valueOf()
      },
      marker: {
        enabled: isMarkerEnabled
      },
      cursor: 'Pointer',
      className: 'display-label',
      data: [
        {
          noTooltip: true,
          x: moment.utc(setupEndTime).valueOf(),
          y: maxSpeedAxis1,
          groupId: 'ed',
          marker: {
            enabled: true,
            fillColor: '#00325C',
            useHTML: true,
            cursor: 'Pointer',
            symbol: 'url(/assets/images/phase-setup_arrow_right.png)',
            className: 'plotline-left',
            radius: 10,
            align: 'right'
          },
          dataLabels: {
            align: 'right',
            enabled: true,
            useHTML: true,
            formatter() {
              return `<div class="plotline-right-label">${moment(this.x).format('HH:mm')}</div>`;
            }
          }
        },
        {
          noTooltip: true,
          x: moment.utc(setupEndTime).valueOf(),
          y: 0,
          groupId: 'ed',
          marker: {
            enabled: false,
            radius: 0
          }
        }
      ],
      lineWidth: 3,
      lineColor: '#00325C',
      showInLegend: false,
      color: '#00325C',
      states: {
        inactive: {
          opacity: 1
        }
      }
    };

    const splitDownTimeEndSeries = {
      name: 'splitDownTimeEndSeries',
      type: 'line',
      stickyTracking: false,
      dragDrop: {
        draggableX: true,
        groupBy: 'groupId',
        dragMaxX: moment(setupEndTime).valueOf()
      },
      data: [
        {
          x: moment.utc(setupEndTime).valueOf(),
          y: maxSpeedAxis1,
          groupId: 'sd',
          marker: {
            enabled: true,
            fillColor: '#00325C',
            radius: 0
          },
          noTooltip: true,
          dataLabels: {
            enabled: true,
            align: 'left',
            useHTML: true,
            formatter() {
              return (
                '<img src="/assets/images/phase-setup_indicator.png" class="flag-indicator"> </img>' +
                `<div class="down-time">${moment(this.x).format('HH:mm')}</div>`
              );
            }
          }
        },
        {
          x: moment.utc(setupEndTime).valueOf(),
          y: 0,
          noTooltip: true,
          groupId: 'sd',
          marker: {
            enabled: true,
            cursor: 'Pointer',
            symbol: 'url(/assets/images/phase-setup_circle.png)',
            radius: 10
          }
        }
      ],
      color: '#00325C',
      lineWidth: 3,
      lineColor: '#00325C',
      showInLegend: false,
      states: {
        inactive: {
          enabled: false,
          opacity: 1
        }
      }
    };

    const shiftSeries = {
      name: this.shiftText,
      type: 'line',
      dashStyle: 'Solid',
      lineWidth: 2,
      showInLegend: false,
      color: '#393d37',
      lineColor: '#393d37',
      states: {
        inactive: {
          enabled: false,
          opacity: 1
        }
      },
      data: [],
      dataLabels: [
        {
          enabled: true,
          align: 'right',
          verticalAlign: 'bottom',
          format: '{point.shiftName}',
          style: {
            textShadow: 'none',
            fontSize: '14px'
          },
          rotation: -90,
          x: 10
        }
      ]
    };

    const productionOrderSeries = {
      name: 'ProductionOrderSeries',
      type: 'line',
      dashStyle: 'Solid',
      lineWidth: 2,
      showInLegend: false,
      color: '#393d37',
      lineColor: '#393d37',
      states: {
        inactive: {
          enabled: false,
          opacity: 1
        }
      },
      data: [],
      dataLabels: [
        {
          enabled: true,
          align: 'right',
          verticalAlign: 'bottom',
          format: '{point.productionOrderNumber}',
          style: {
            textShadow: 'none',
            fontSize: '14px'
          },
          rotation: -90,
          x: 10,
          y: 100
        }
      ]
    };

    const speed2Series = {
      name: this.speed2SeriesText,
      data: [],
      type: 'line',
      lineColor: '#393d37',
      showInLegend: true,
      turboThreshold: 10000,
      step: 'left',
      color: '#393d37',
      inside: true,
      states: {
        inactive: {
          opacity: 1
        }
      },
      marker: {
        enabled: isMarkerEnabled
      },
      yAxis: 1
    };

    const washSignalSeries = {
      name: this.washSeriesText,
      data: [],
      type: 'area',
      lineColor: '#334BFF',
      showInLegend: true,
      step: 'left',
      color: '#334BFF',
      fillColor: '#334BFF',
      turboThreshold: 10000,
      inside: true,
      states: {
        inactive: {
          opacity: 1
        }
      },
      marker: {
        enabled: isMarkerEnabled
      },
      yAxis: 0
    };

    const downTimeSeries = {
      name: this.downModeText,
      type: 'area',
      data: [],
      lineColor: '#00325C',
      showFirstLabel: false,
      showInLegend: false,
      enableMouseTracking: false,
      color: '#689576',
      fillColor: {
        pattern: {
          path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            strokeWidth: 1
          },
          width: 10,
          height: 10,
          opacity: 0.4,
          borderRadius: 0.9
        },
        borderColor: '#00325C'
      },
      fillOpacity: 0.8,
      marker: {
        enabled: true,
        fillColor: '#00325C',
        symbol: 'circle'
      },
      dataLabels: {
        enabled: true,
        backgroundColor: '#00325C',
        shape: 'flags',
        style: {
          color: 'white',
          fontWeight: 'bold',
          textShadow: 'none'
        },
        useHTML: true,
        formatter() {
          return `<span style="padding: 5px;">${moment(this.x).format('HH:mm')}</span>`;
        }
      },
      states: {
        inactive: {
          opacity: 1
        }
      }
    };

    const wasteAssignmentSeries = {
      color: 'red',
      name: 'Waste',
      yAxis: 0,
      type: 'line',
      data: [],
      lineWidth: 0,
      marker: {
        enabled: true,
        radius: 5,
        symbol: 'circle'
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      }
    };

    const productionOrderData = result.reduce((productionResult, { externalProductionOrderId }) => {
      if (!productionResult.some((order) => order.externalProductionOrderId === externalProductionOrderId)) {
        productionResult.push({
          externalProductionOrderId,
          groupItem: result.filter((v) => v.externalProductionOrderId === externalProductionOrderId)
        });
      }
      return productionResult;
    }, []);

    productionOrderData.forEach((productionOrder) => {
      if (productionOrder.groupItem[0].externalProductionOrderId !== null) {
        productionOrderSeries.data.push({
          x: moment.utc(productionOrder.groupItem[0].periodStart).valueOf(),
          y: 0,
          productionOrderNumber: '',
          tooltipValue: this.getProductionOrderTooltipText(productionOrder)
        });
        productionOrderSeries.data.push({
          x: moment.utc(productionOrder.groupItem[0].periodStart).valueOf(),
          y: maxSpeedAxis1,
          productionOrderNumber: productionOrder.groupItem[0].productionOrderData.productionOrderNumber,
          tooltipValue: this.getProductionOrderTooltipText(productionOrder)
        });
        productionOrderSeries.data.push({
          x: moment.utc(productionOrder.groupItem[0].periodStart).valueOf(),
          y: null,
          productionOrderNumber: null,
          tooltipValue: ''
        });
      }
    });

    wasteReasons.forEach((wasteData) => {
      wasteAssignmentSeries.data.push({
        x: moment.utc(wasteData.timeStamp).valueOf(),
        y: 300, // waste series marker position on y-axis.
        tooltipValue: this.wasteTooltip(wasteData)
      });
    });

    result.forEach((status) => {
      if (status.workCenterStatus === 'Setup') {
        setupSeries.data.push({
          x: moment.utc(status.periodStart).valueOf(),
          y: 0,
          tooltipValue: this.getSetupPhaseTooltipText(status)
        });
        setupSeries.data.push({
          x: moment.utc(status.periodStart).valueOf(),
          y: setupDownLimit,
          tooltipValue: this.getSetupPhaseTooltipText(status)
        });

        setupSeries.data.push({
          x: status.periodEnd === null ? moment.utc().valueOf() : moment.utc(status.periodEnd).valueOf(),
          y: setupDownLimit,
          tooltipValue: this.getSetupPhaseTooltipText(status)
        });
        setupSeries.data.push({
          x: status.periodEnd === null ? moment.utc().valueOf() : moment.utc(status.periodEnd).valueOf(),
          y: 0,
          tooltipValue: this.getSetupPhaseTooltipText(status)
        });
        setupSeries.data.push({
          x: status.periodEnd === null ? moment.utc().valueOf() : moment.utc(status.periodEnd).valueOf(),
          y: null,
          tooltipValue: ''
        });
      } else if (status.workCenterStatus === 'Interruption' || status.externalDowntimeLocalCode !== null) {
        downSeries.data.push({
          x: moment(status.periodStart).valueOf(),
          y: 0,
          externalDowntimeLocalCode: '',
          tooltipValue: this.getDowntimeTooltipText(status)
        });

        downSeries.data.push({
          x: moment.utc(status.periodStart).valueOf(),
          y: setupDownLimit,
          externalDowntimeLocalCode: status.externalDowntimeLocalCode == null ? '' : status.externalDowntimeLocalCode,
          tooltipValue: this.getDowntimeTooltipText(status)
        });

        downSeries.data.push({
          x: status.periodEnd === null ? moment().valueOf() : moment(status.periodEnd).valueOf(),
          y: setupDownLimit,
          externalDowntimeLocalCode: '',
          tooltipValue: this.getDowntimeTooltipText(status)
        });

        downSeries.data.push({
          x: status.periodEnd === null ? moment().valueOf() : moment(status.periodEnd).valueOf(),
          y: 0,
          externalDowntimeLocalCode: '',
          tooltipValue: this.getDowntimeTooltipText(status)
        });

        downSeries.data.push({
          x: status.periodEnd === null ? moment().valueOf() : moment(status.periodEnd).valueOf(),
          y: null,
          externalDowntimeLocalCode: '',
          tooltipValue: ''
        });
      }
      targetSpeedSeries.data.push({
        x: moment.utc(status.periodStart).valueOf(),
        y: status.targetSpeed.value,
        tooltipValue: `<div class='generaltooltip'> ● ${this.targetSpeedText} <br>
          ${moment(status.periodStart).format('HH:mm:ss')}<br>
          ${status.targetSpeed.value}
        </div>`
      });
      shiftSeries.data.push({
        x: moment.utc(status.shiftStartTime).valueOf(),
        y: 0,
        shiftName: '',
        tooltipValue: `<div class='generaltooltip'> ● ${this.shiftText}: <b>${status.shiftTypeName}</b><br>
          ${this.externalShiftTypeText}: <b>${status.externalShiftTypeId}</b><br>
          ${this.dateText}: <b>${this.formatDateTime(status.shiftStartTime)}</b>
        </div>`
      });
      shiftSeries.data.push({
        x: moment.utc(status.shiftStartTime).valueOf(),
        y: maxSpeedAxis1,
        shiftName: status.shiftTypeName,
        tooltipValue: `<div class='generaltooltip'> ● ${this.shiftText}: <b>${status.shiftTypeName}</b><br>
          ${this.externalShiftTypeText}: <b>${status.externalShiftTypeId}</b><br>
          ${this.dateText}: <b>${this.formatDateTime(status.shiftStartTime)}</b>
        </div>`
      });
      shiftSeries.data.push({
        x: moment.utc(status.shiftStartTime).valueOf(),
        y: null,
        shiftName: null,
        tooltipValue: ''
      });
    });

    plotBandSeries.data.push([moment.utc(setupStartTime).valueOf(), maxSpeedAxis1 - plotBandHeight]);
    plotBandSeries.data.push([moment.utc(setupEndTime).valueOf(), maxSpeedAxis1 - plotBandHeight]);

    plotBandSeries.data.push([moment.utc(setupEndTime).valueOf(), maxSpeedAxis1]);
    plotBandSeries.data.push([moment.utc(setupStartTime).valueOf(), maxSpeedAxis1]);

    allDataSignal.forEach((signalData) => {
      if (signalData.telemetryQuery.wellKnownSignal === 'speed') {
        signalData.telemetryData.forEach((speedSignalData) => {
          speedSeries.data.push({
            x: moment(speedSignalData.timestamp).valueOf(),
            y: speedSignalData.value < 0 ? 0 : speedSignalData.value,
            tooltipValue: `<div class='generaltooltip'> ● ${this.speedSeriesText} <br>
                ${this.formatDateTime(speedSignalData.timestamp)}<br>
                ${speedSignalData.value.toFixed(0)}
              </div>`
          });
        });
        this.availableSignalSeries.push(speedSeries);
      } else if (signalData.telemetryQuery.wellKnownSignal === 'machine_run') {
        if (signalData.telemetryData.length > 0) {
          signalData.telemetryData.forEach((runSignalData) => {
            if (runSignalData.value > 0) {
              runSeries.data.push({
                x: moment(runSignalData.timestamp).valueOf(),
                y: runLimit,
                tooltipValue: `<div class='generaltooltip'> ● ${this.runModeText}<br>
                  ${this.formatDateTime(runSignalData.timestamp)}
                </div>`
              });
            }
          });
          this.availableSignalSeries.push(runSeries);
        }
      } else if (signalData.telemetryQuery.wellKnownSignal === 'speed2') {
        if (signalData.telemetryData.length > 0) {
          signalData.telemetryData.forEach((speed2Data) => {
            speed2Series.data.push({
              x: moment(speed2Data.timestamp).valueOf(),
              y: speed2Data.value < 0 ? 0 : speed2Data.value,
              tooltipValue: `<div class='generaltooltip'> ● ${signalData.telemetryQuery.wellKnownSignal}<br>
                ${this.formatDateTime(speed2Data.timestamp)}
              </div>`
            });
          });
          this.availableSignalSeries.push(speed2Series);
        }
      } else if (signalData.telemetryQuery.wellKnownSignal === 'wash') {
        if (signalData.telemetryData.length > 0) {
          signalData.telemetryData.forEach((washSignal) => {
            if (washSignal.value === 1) {
              washSignalSeries.data.push({
                x: moment(washSignal.timestamp).valueOf(),
                y: setupDownLimit,
                tooltipValue: `<div class='generaltooltip'> ● ${signalData.telemetryQuery.wellKnownSignal}<br>
                  ${this.formatDateTime(washSignal.timestamp)}
                </div>`
              });
            } else {
              washSignalSeries.data.push({
                x: moment(washSignal.timestamp).valueOf(),
                y: 0,
                tooltipValue: `<div class='generaltooltip'> ● ${signalData.telemetryQuery.wellKnownSignal}<br>
                  ${this.formatDateTime(washSignal.timestamp)}
                </div>`
              });
            }
          });
          this.availableSignalSeries.push(washSignalSeries);
        }
      } else if (signalData.telemetryQuery.wellKnownSignal !== SignalType.counter_qty) {
        const newSeries = {
          name: this.getNewSeriesName(signalData.telemetryQuery.wellKnownSignal),
          data: [],
          type: 'line',
          showInLegend: true,
          lineColor: '#393d37',
          step: 'left',
          color: '#393d37',
          turboThreshold: 10000,
          inside: true,
          states: {
            inactive: {
              opacity: 1
            }
          }
        };
        if (signalData.telemetryData.length > 0) {
          signalData.telemetryData.forEach((newSeriesData) => {
            newSeries.data.push({
              x: moment(newSeriesData.timestamp).valueOf(),
              y: newSeriesData.value < 0 ? 0 : newSeriesData.value,
              tooltipValue: `<div class='generaltooltip'> ● ${signalData.telemetryQuery.wellKnownSignal}<br>
                ${this.formatDateTime(newSeriesData.timestamp)}
              </div>`
            });
          });
          this.availableSignalSeries.push(newSeries);
        }
      }
    });
    this.availableSignalSeries = this.availableSignalSeries.sort((a, b) => (a.name > b.name ? 1 : -1));

    this.fullViewSeriesData = [];
    this.fullViewSeriesData.push(setupSeries);
    this.fullViewSeriesData.push(downSeries);
    this.fullViewSeriesData = this.fullViewSeriesData.concat(this.availableSignalSeries);
    this.fullViewSeriesData.push(shiftSeries);
    this.fullViewSeriesData.push(productionOrderSeries);
    this.fullViewSeriesData.push(targetSpeedSeries);
    this.fullViewSeriesData.push(wasteAssignmentSeries);

    if (ChartType.downtimeTask && downtimeResult !== null && downtimeResult !== undefined) {
      downTimeSeries.data.push([moment.utc(downtimeResult.downtime.startTime).valueOf(), maxSpeedAxis1]);
      downTimeSeries.data.push([
        downtimeResult.downtime.endTime === null ? moment.utc().valueOf() : moment.utc(downtimeResult.downtime.endTime).valueOf(),
        maxSpeedAxis1
      ]);
    }
    switch (chartMode) {
      case ChartType.fullView:
        return this.fullViewSeriesData;
      case ChartType.downtimeTask:
        return [setupSeries, runSeries, speedSeries, downSeries, downTimeSeries];
      case ChartType.finishPhase:
        return [setupSeries, runSeries, speedSeries, downSeries];
      case ChartType.editSetup:
        return [setupSeries, runSeries, downSeries, speedSeries, plotBandSeries, setupStartSeries, setupEndSeries];
      case ChartType.splitDowntime:
        return [setupSeries, runSeries, downSeries, speedSeries, splitDownTimeEndSeries];
      case ChartType.editDowntime:
        return [setupSeries, runSeries, downSeries, speedSeries, plotBandSeries, setupStartSeries, editDowntimeEndSeries];
    }
  }

  public newSeriesNames = (): Observable<any> => this.translate.get(['MACHINE_CHART.PRODUCTION_CHANGE_SERIES']);

  private wasteTooltip(wasteAssignment: WasteAssignmentInTime): string {
    return `<div class='generaltooltip'> ● ${this.translate.instant('MACHINE_CHART.WASTE_ASSIGNMENT')}<br>
        ${this.formatDateTime(wasteAssignment.timeStamp)}
        ${this.wasteReasons(wasteAssignment?.wastesPerReason)}<br>
        ${this.getMaculatureText(wasteAssignment)}
      </div>`;
  }

  private getMaculatureText(wasteAssignment: WasteAssignmentInTime) {
    if (wasteAssignment?.maculature?.value > 0) {
      return `
         ${this.translate.instant('MACHINE_CHART.MACULATURE')}: <b>
         ${this.formatQuantityPipe.transform(wasteAssignment.maculature)}</b>`;
    } else {
      return '';
    }
  }

  private wasteReasons(wastes: WastePerReason[]): string {
    let text = '';
    wastes.forEach((element) => {
      text += `<br>
      ${this.translate.instant('MACHINE_CHART.WASTE')}: <b>${this.formatQuantityPipe.transform(element.waste)}</b><br>
      ${this.translate.instant('MACHINE_CHART.WASTE_REASON')}: <b>${element.externalWasteReasonCode} ${element.description}</b>`;
    });

    return text;
  }

  public getNewSeriesName(seriesName: string): string {
    this.newSeriesNames().subscribe((result) => {
      Object.keys(result).map((key) => {
        switch (key) {
          case 'MACHINE_CHART.PRODUCTION_CHANGE_SERIES':
            this.productionChangeSeries = result[key];
            break;
          case 'MACHINE_CHART.NONE':
            this.productionChangeSeries = result[key];
            break;
        }
      });
      switch (seriesName) {
        case 'production_change':
          this.newSeriesName = this.productionChangeSeries;
          break;
        case 'none':
          this.newSeriesName = this.productionChangeSeries;
          break;
        default:
          this.newSeriesName = seriesName;
          break;
      }
    });
    return this.newSeriesName ?? seriesName;
  }

  public getProductionOrderTooltipText(productionOrder: any): string {
    return `<div class='generaltooltip'> ● <b>${productionOrder.groupItem[0].productionOrderData.productionOrderNumber}</b><br>
            ${this.finishGoodArticleNumberText}: <b>${productionOrder.groupItem[0].productionOrderData.finishedGoodArticleNumber}</b><br>
            ${this.finishGoodArticleNameText}: <b>${productionOrder.groupItem[0].productionOrderData.finishedGoodArticleName}</b><br>
            ${this.configNameNumberText}: <b> ${productionOrder.groupItem[0].productionOrderData.configurationNumber} ${
      productionOrder.groupItem[0].productionOrderData.configurationName
    }</b><br>
            ${this.goodQuantityUnitText}: <b>${this.formatQuantityPipe.transform(
      productionOrder.groupItem[0].producedMaterialData.goodQuantity
    )}</b><br>
            ${this.wasteQuantMalQuntText}: <b>${this.formatQuantityPipe.transform(
      productionOrder.groupItem[0].producedMaterialData.waste
    )}, ${this.formatQuantityPipe.transform(productionOrder.groupItem[0].producedMaterialData.maculature)}</b>
          </div>`;
  }

  public getDowntimeTooltipText(status: ProductionPeriodDetailData): string {
    return `<div class='downtimetooltip'> ● ${this.downModeText} <br>
            ${this.formatDateTime(status.periodStart)}<br>
            ${this.downtimeCodeText}: <b>${status.externalDowntimeLocalCode}</b><br>
            ${this.downtimeCommentText}: <b>${status.downtimeComment === null ? this.noCommentText : status.downtimeComment}</b>
            ${this.getDowntimeWasteHTMLString(status)}
          </div>`;
  }

  public getSetupPhaseTooltipText(status: ProductionPeriodDetailData): string {
    return `<div class='setuptooltip'> ● ${this.setupModeText} <br>
            ${this.formatDateTime(status.periodEnd)}<br>
            ${this.setupKindText}: <b>${status.externalSetupParameterKindId} ${status.setupParameterKindDescription}</b>
            ${this.getSetupWasteHtmlString(status)}
          </div>`;
  }

  public getSetupWasteHtmlString(status: ProductionPeriodDetailData): string {
    let setupWaste = '';
    if (status.setupPhaseWastesPerReason && status.setupPhaseWastesPerReason.length > 0) {
      status.setupPhaseWastesPerReason.map((item) => {
        if (item.waste && item.waste?.value > 0) {
          setupWaste += `<br>${this.wasteQuantityText}: <b>${this.formatQuantityPipe.transform(item.waste)}</b>`;
        }
        if (item.externalWasteReasonCode && item.externalWasteReasonCode.trim().length > 0) {
          setupWaste += `<br>${this.wasteReasonText}: <b>${item.externalWasteReasonCode} ${item.description}</b>`;
        }
      });
    }
    if (status.setupPhaseMaculature && status.setupPhaseMaculature?.value > 0) {
      setupWaste += `<br>${this.maculatureQuantityText}: <b> ${this.formatQuantityPipe.transform(status.setupPhaseMaculature)}</b>`;
    }
    return setupWaste;
  }

  public getDowntimeWasteHTMLString(status: ProductionPeriodDetailData): string {
    let downTimeWaste = '';
    if (status.downtimeWastesPerReason && status.downtimeWastesPerReason.length > 0) {
      status.downtimeWastesPerReason?.map((item) => {
        if (item.waste && item.waste?.value > 0) {
          downTimeWaste += `<br>${this.wasteQuantityText}: <b>${this.formatQuantityPipe.transform(item.waste)}</b>`;
        }
        if (item.externalWasteReasonCode && item.externalWasteReasonCode.trim().length > 0) {
          downTimeWaste += `<br>${this.wasteReasonText}: <b>${item.externalWasteReasonCode} ${item.description}</b>`;
        }
      });
    }
    if (status.downtimeMaculature && status.downtimeMaculature?.value > 0) {
      downTimeWaste += `<br>${this.maculatureQuantityText}: <b>${this.formatQuantityPipe.transform(status.downtimeMaculature)}</b>`;
    }
    return downTimeWaste;
  }

  public getSeriesTitleText(counterUnitId: string): string {
    if (counterUnitId === 'SHT') {
      return this.shtCounterText;
    }
    if (counterUnitId === 'PCS') {
      return this.pcsCounterText;
    }
    return counterUnitId;
  }

  private formatDateTime(dateTimeText: string | null): string {
    if (!dateTimeText) {
      return '–';
    }
    return moment(dateTimeText).format('DD.MM.yyyy HH:mm:ss');
  }
}
