<section class="chronos-dialog--size-md">
  <div class="downtime">
    <div class="downtime__form">
      <div class="downtime__waste-assignment-row">
        <div class="row">
          <div class="col-3 downtime__waste-assignment">
            <div>{{ 'WASTE_ASSIGNMENT.MACULATURE' | translate }}</div>
            <div>
              <lib-input-number
                [ngModel]="maculature"
                [inputStyle]="{ width: '100px', textAlign: 'end' }"
                [disabled]="true"
                [unit]="this.maculatureUnitId | uppercase"
                [showUnit]="false"
              ></lib-input-number>
              <span class="unit-span">{{ maculatureUnitId }}</span>
            </div>
          </div>
          <div class="col-3 downtime__waste-assignment">
            <div>{{ 'WASTE_ASSIGNMENT.WASTE' | translate }}</div>
            <div>
              <lib-input-number
                [ngModel]="waste"
                [inputStyle]="{ width: '100px', textAlign: 'end' }"
                [disabled]="true"
                [unit]="this.wasteUnitId | uppercase"
                [showUnit]="false"
              ></lib-input-number>
              <span class="unit-span">{{ wasteUnitId }}</span>
            </div>
          </div>
          <div class="col-6 downtime__waste-assignment" [ngClass]="{ 'downtime--required': waste > 0 && !selectedWasteReason }">
            <div>{{ 'WASTE_ASSIGNMENT.REASON' | translate }}</div>
            <div>
              <lib-dropdown
                [(ngModel)]="selectedWasteReason"
                [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
                [options]="downtimeViewData.wasteReasonList"
                [inlineStyle]="{ width: '350px' }"
                [disabled]="waste === 0"
              ></lib-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="downtime__row">
        <div class="row">
          <div class="col-2">
            {{ 'TASKS.CURRENT_REASON' | translate }}
          </div>
          <div class="col-8">
            <div class="downtime__details" *ngIf="downtime.downtimeReasonLocalDescription">
              <span *ngIf="downtime.downtimeReasonCentralDescription">
                {{ downtime.downtimeReasonCentralDescription }}
              </span>
              <span *ngIf="downtime.downtimeCategoryName">
                {{ ' > ' + downtime.downtimeCategoryName }}
              </span>
              <span>
                {{ ' > ' + downtime.downtimeReasonLocalDescription }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="downtime__row">
        <div class="row">
          <div class="col-2">{{ 'TASKS.ASSIGN_REASON' | translate }}</div>
          <div class="col-8">
            <p-autoComplete
              #reasonAutoComplete
              id="reasonAutoComplete"
              [(ngModel)]="reasonAutoCompleteModel"
              [suggestions]="reasons"
              (completeMethod)="filterAutoCompleteReasons($event)"
              [forceSelection]="true"
              [inputStyle]="{ width: '100%' }"
              [style]="{ width: '100%' }"
              field="description"
              [autofocus]="true"
              (onSelect)="onAutoCompleteReasonSelect(reasonAutoCompleteModel)"
              (onClear)="clearTreeSelection()"
              [placeholder]="'TASKS.AUTOCOMPLETE_PLACEHOLDER' | translate"
            >
              <ng-template let-reason pTemplate="item">
                {{ reason.description }}
              </ng-template>
              <ng-template let-reason pTemplate="selectedItem">
                {{ reason.description }}
              </ng-template>
            </p-autoComplete>
          </div>
        </div>

        <div class="reason-browser">
          <div
            class="reason-browser__column reason-browser__category-group"
            [ngClass]="{ 'downtime--required': isDowntimeReasonRequired() }"
          >
            <div class="reason-browser__column-title">{{ 'TASKS.DOWNTIME_GROUP' | translate }}</div>
            <ul class="reason-browser__categories">
              <li *ngFor="let category of downtimeViewData.reasonTreeList">
                <a
                  class="reason-browser__category-name"
                  (click)="onCategoryGroupSelect(category.id)"
                  [ngClass]="{ active: category.id === selectedCategoryGroup }"
                >
                  {{ category.name }}
                </a>
              </li>
            </ul>
          </div>

          <div
            *ngIf="selectedCategoryGroup"
            class="reason-browser__column reason-browser__category"
            [ngClass]="{ 'downtime--required': !selectedCategory }"
          >
            <div class="reason-browser__column-title">{{ 'TASKS.CENTRAL_DOWNTIME_CODE' | translate }}</div>
            <ul class="reason-browser__categories">
              <li *ngFor="let category of filteredCategories">
                <a
                  class="reason-browser__category-name"
                  (click)="onCategorySelect(category.id)"
                  [ngClass]="{ active: category.id === selectedCategory }"
                >
                  {{ category.description }}
                </a>
              </li>
            </ul>
          </div>

          <div
            class="reason-browser__column reason-browser__reason"
            [ngClass]="{ 'downtime--required': !reasonAutoCompleteModel }"
            *ngIf="filteredReasons"
          >
            <div class="reason-browser__column-title">{{ 'TASKS.LOCAL_DOWNTIME_CODE' | translate }}</div>
            <ul class="reason-browser__reasons">
              <li *ngFor="let reason of filteredReasons">
                <a
                  class="reason-browser__reason-name"
                  (click)="onReasonSelect(reason)"
                  [ngClass]="{ active: reason.id === reasonAutoCompleteModel?.id }"
                >
                  {{ reason.description }}
                </a>
              </li>
            </ul>
          </div>

          <div class="reason-browser__placeholder">
            <div *ngIf="!selectedCategoryGroup" class="reason-browser__intro">
              <i class="icon-arrow-right-back"></i>
              <span class="text">{{ 'TASKS.PLEASE_SELECT' | translate }}</span>
              <i class="icon-info-outline"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="downtime__row" [ngClass]="{ 'downtime--required': isDowntimeInfoFieldRequired() }">
        <div class="row">
          <div class="col-2">{{ 'TASKS.INFO' | translate }}</div>
          <div class="col-8">
            <textarea class="downtime__textarea" [maxLength]="255" [rows]="5" [(ngModel)]="reasonInfo" pInputTextarea></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <lib-modal-footer
    [whenLoadingTopic]="LOADING_TOPIC"
    [submitLabel]="'TASKS.OK'"
    [submitDisabled]="isButtonDisabled()"
    [checkBox]="checkBoxClicked"
    [checkBoxCommand]="isCheckBoxCommand"
    [isAutoFocusOnSubmitButton]="false"
    (submitClicked)="submitReason()"
    (declineClicked)="closeModal()"
    (checkBoxClicked)="setCheckBox($event)"
  ></lib-modal-footer>
</section>
